// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tn1uhOyA{align-items:center;display:flex;flex-direction:column;flex-grow:1;justify-content:center;min-height:60vh;padding:var(--spacing-md)}.Tn1uhOyA .xKbmrbX5{transform:scale(3)}.nv\\+snsf3{align-items:center;display:flex;flex-direction:column}._79kjy9Qs{font-size:1rem;line-height:1.14;margin-bottom:1em;max-width:60ch;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "Tn1uhOyA",
	"spinner": "xKbmrbX5",
	"content": "nv+snsf3",
	"message": "_79kjy9Qs"
};
module.exports = ___CSS_LOADER_EXPORT___;
